import { lazy, Suspense } from 'react';
import { BrowserRouter, useRoutes, RouteObject, useLocation } from 'react-router-dom';
import Panel from './views/Panel'
import Login from './views/entrance/Login';
import Register from './views/entrance/Register';
import FindPw from './views/entrance/FindPw';
import { config, get } from '@/common';

// 404处理
let default_url: any = null
const Not = () => {
    window.location.href = default_url ? default_url : '/login'
    return (<i></i>)
}

let common: any = {}

// 固定页面
let routes: RouteObject[] = [
    {
        element: <Panel common={common} />,
        children: []
    },
    {
        path: 'login',
        element: <Login />
    },
    // {
    //     path: 'register',
    //     element: <Register />
    // },
    {
        path: 'find_pw',
        element: <FindPw />
    },
    {
        path: '*',
        element: <Not />
    }
]

let Routes = () => {
    return useRoutes(routes)
}

// 平台初始化操作
const setDefault = () => {
    // 获取常量
    get('/opens/constants').then(data => {
        localStorage.setItem('constants', JSON.stringify(data.data))
    })
    // 获取配置
    get('/_system/opens/config').then(data => {
        localStorage.setItem('config', JSON.stringify(data.data))
    })
    // 获取平台
    get('/_system/opens/platforms').then(data => {
        localStorage.setItem('platforms', JSON.stringify(data.data))
    })
    // 单点登陆
    if (!localStorage.getItem('member')) {
        let token = (new URL(window.location.href)).searchParams.get('_token')
        if (!token) return
        routes.pop()
        fetch(config.api.url._system + '/loginToken', {
            method: "POST",
            mode: 'cors',
            body: JSON.stringify({
                token
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.json()).then((data: any) => {
            if (data.code) return
            data = data.data
            localStorage.setItem('member', JSON.stringify(data.member))
            localStorage.setItem('module', JSON.stringify(data.module))
            localStorage.setItem('config', JSON.stringify(data.config))
            window.location.href = '/'
        })
    } else {
        setCommon()
        let modules = JSON.parse(localStorage.getItem('module') || '{}')
        if (modules[config.app.id]) getModules(modules[config.app.id])
    }
}

// 登陆后通用操作
const setCommon = () => {
    const member = JSON.parse(localStorage.getItem('member') || '{}')
    // WebSocket
    let ws = new WebSocket(config.api.im)
    let heartbeat: any
    ws.onopen = () => {
        console.log('连线');
        // 在线通知
        ws.send(JSON.stringify({
            type: 'online',
            member_id: member['id'],
        }))
        // 心跳30s
        heartbeat = setInterval(() => {
            ws.send(JSON.stringify({
                type: 'heartbeat',
            }))
        }, 30000)
    }
    ws.onclose = () => {
        console.log('掉线');
        clearInterval(heartbeat)
    }
    ws.onmessage = (evt: any) => {
        let one = JSON.parse(evt.data)
        // if (one.type === 'return') {
        //     common.chat_ids = one.chat_ids
        //     return
        // }
        if (common.onmessages && typeof (common.onmessages) == "function") common.onmessages(one)
        if (common.onmessage && typeof (common.onmessage) == "function") common.onmessage(one)
    }
    common.ws = ws
}

function Main() {
    setDefault()
    return (
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    )
}

// 无限级处理
const getModules = (m: any) => {
    m.forEach((e: any) => {
        if (e.children) {
            getModules(e.children)
        } else if (routes[0].children) {
            if (!routes[0].children.length) default_url = e.url
            const Element = lazy(() => import(`@/views${pathToModule(e.url)}`))
            routes[0].children.push({
                path: e.url,
                element: <Suspense fallback={null}><Element ope={e.ope || []} common={common} /></Suspense>
            })
        }
    });
    // return m
}

// 路径转换模块名
const pathToModule = (path: string) => {
    let arr: string[] = path.split('/')
    let module: string[] = arr.splice(arr.length - 1, 1)[0].split('_')
    module.forEach((e, i) => {
        module[i] = e.charAt(0).toUpperCase() + e.slice(1)
    });
    return arr.join('/') + '/' + module.join('')
}

export default Main