import React, { post, useState, MyIcon, useEffect, useNavigate, useLocation, get, Func, config, useRef } from '@/common';
import { Outlet } from 'react-router-dom';
import { Layout, Menu, Avatar, Dropdown, Modal, Badge, message } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  DownOutlined,
  AppstoreOutlined,
  BellOutlined
} from '@ant-design/icons';
import '@/assets/style/panel.scss';
import Func1 from '@/Func'

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

function Main(props: any) {
  const navigate = useNavigate()
  const path = useLocation().pathname
  const member = JSON.parse(localStorage.getItem('member') || '{}')
  // const platform_now = localStorage.getItem('platform_now') || 'system'
  // const platform_now = path.split('/')[1]
  // const platform_now = 'system'
  // const [magnify_status, setMagnifyStatus] = useState(false);
  const [dom_tenant, setDomTenant] = useState(<React.Fragment />);
  const [collapsed, setCollapsed] = useState(false);
  const [dom_menu, setDomMenu] = useState<Array<React.ReactNode>>([]);
  const [dom_sider, setDomSider] = useState<Array<React.ReactNode>>([]);
  const [menu_selected_keys, setMenuSelectedKeys] = useState<Array<string>>([]);
  const [sider_open_keys, setSiderOpenKeys] = useState<Array<string>>([]);
  const [sider_selected_keys, setSiderSelectedKeys] = useState<Array<string>>([]);
  const [is_magnify, setIsMagnify] = useState(false)
  const platforms = JSON.parse(localStorage.getItem('platforms') || '{}')
  const modules = JSON.parse(localStorage.getItem('module') || '{}')
  const [msg_unread, setMsgUnread] = useState(0)
  const msg_unread_ref = useRef(0)

  let platforms_menu: any = []
  Object.keys(platforms).forEach((key) => {
    if (key === config.app.id || !modules[key]) return
    platforms_menu.push(
      {
        key,
        label: (
          <a target={platforms[key].target} href={platforms[key].target === '_self' ? `${platforms[key].url}?_token=${member.token}` : platforms[key].url}>
            {platforms[key].name}
          </a>
        ),
      })
  })

  const user_drop: any = [
    {
      onClick: () => { navigate('/system/my/my') },
      icon: <MyIcon type="icon-gerenzhongxin" />,
      label: (<div>个人中心</div>),
    },
    {
      onClick: () => { logout() },
      icon: <MyIcon type="icon-tuichu" />,
      label: (<div>退出登陆</div>),
    }
  ]

  useEffect(() => {
    // 获取组织列表
    getOrganization()
    // 消息通知
    setOnmessage()
  }, [])

  const setOnmessage = () => {
    // 统一通知处理
    props.common.onmessages = (one: any) => {
      if (one.type === 'text' || one.type === 'image' || one.type === 'inform') {
        message.info(`${one.user.name}:${one.content}`);
        msgAuido()
      }
      if (one.type === 'text' || one.type === 'image') {
        msg_unread_ref.current += 1
        setMsgUnread(msg_unread_ref.current)
      }
    }
  }

  const msgAuido = () => {
    let mp3 = new Audio('/file/new_msg.mp3')
    mp3.play()
  }

  const getOrganization = () => {
    get('/_system/member/organizations').then(data => {
      data = data.data
      let items: any = []
      let key = 0
      if (!data.length) return
      data.forEach((e: any, i: number) => {
        if (member.organization_id_now === e.id) {
          key = i
          return
        }
        items.push({
          onClick: () => { Func1.switchOrganization(e.id) },
          label: (<div>{e.name}</div>),
        })
        // menu.push(<Menu.Item key={i} onClick={() => { Func1.switchOrganization(e.id) }}>{e.name}</Menu.Item>)
      })
      // let overlay = (
      //   <Menu selectedKeys={[key.toString()]}>
      //     {menu}
      //   </Menu>
      // )
      let dom_tenant = items.length ?
        <Dropdown className="organization" menu={{ items }} >
          <div className="btn">
            <MyIcon type="icon-gongsixinxi" /><span>{data[key].name}</span><DownOutlined />
          </div>
        </Dropdown >
        :
        <div className='organization'>
          <div className="btn">
            <MyIcon type="icon-gongsixinxi" /><span>{data[key].name}</span>
          </div>
        </div>

      setDomTenant(dom_tenant)
    })
  }

  useEffect(() => {
    setMenu()
    getImList()
  }, [path])


  const getImList = () => {
    get('/imList').then(data => {
      let msg_unread_num = 0
      data.data.forEach((e: any) => {
        msg_unread_num += e.unread
      });

      msg_unread_ref.current = msg_unread_num
      setMsgUnread(msg_unread_num)
    })
  }

  const logout = () => {
    Modal.confirm({
      title: "确认是否退出登陆？",
      onOk() {
        post('/_system/logout')
        localStorage.removeItem('member')
        localStorage.removeItem('module')
        let url = '/login'
        // if (platform_now !== 'system') url = `/${platform_now}/login`
        navigate(url)
      }
    })
  }
  const setMenu = () => {
    let menu: Array<React.ReactNode> = [], sider: Array<React.ReactNode> = []
    let menu_selected_key: number = 0, sider_open_key: number = 0, sider_selected_key: number = 0
    let pathname = path.split('/')
    // 从本地获取模块信息
    // let modules = JSON.parse(localStorage.getItem('module') || '{}')[platform_now]
    modules[config.app.id].forEach((e: any, k: number) => {
      // // 默认页面
      // if (k === 0) default_url = e.url
      // 设置顶部菜单
      menu.push(
        <Menu.Item key={e.id} icon={<MyIcon type={`icon-${e.ico}`} />} onClick={() => { navigate(e.url) }}>{e.name}</Menu.Item>
      )
      if (pathname[1] === e.path) {
        menu_selected_key = e.id
        if (e.children !== undefined) {
          e.children.forEach((e_1: any) => {
            if (pathname[2] === e_1.path) sider_open_key = e_1.id
            if (e_1.children) {
              let item: any = []
              e_1.children.forEach((e_2: any) => {
                if (pathname[2] === e_1.path && pathname[3] === e_2.path) sider_selected_key = e_2.id
                item.push(<Menu.Item key={e_2.id} onClick={() => { navigate(e_2.url) }}>{e_2.name}</Menu.Item>)
              })
              sider.push(<SubMenu key={e_1.id} icon={<MyIcon type={`icon-${e_1.ico}`} />} title={e_1.name}>{item}</SubMenu>)
            } else {
              if (pathname[2] === e_1.path) sider_selected_key = e_1.id
              sider.push(
                <Menu.Item key={e_1.id} icon={<MyIcon type={`icon-${e_1.ico}`} />} onClick={() => { navigate(e_1.url) }}>
                  {e_1.name}
                </Menu.Item>
              )
            }
          });
        }
      }
    });
    setDomMenu(menu)
    setDomSider(sider)
    setMenuSelectedKeys([menu_selected_key.toString()])
    setSiderOpenKeys([sider_open_key.toString()])
    setSiderSelectedKeys([sider_selected_key.toString()])
  }
  return (
    <Layout className="mos">
      <Sider className={`sider ${is_magnify ? 'hide' : null}`} theme="light" trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <a onClick={() => navigate(platforms[config.app.id].url)}>{platforms[config.app.id].name}</a>
        </div>
        {/* <div className="logo" onClick={() => navigate('/')}> */}
        {/* {collapsed ? <img src="/images/logo.jpg" alt="logo"></img> : <img src="/images/logo_big.jpg" alt="logo"></img>} */}
        {/* </div> */}
        {
          collapsed ? <Menu selectedKeys={sider_selected_keys} mode="inline">{dom_sider}</Menu> : <Menu openKeys={sider_open_keys} selectedKeys={sider_selected_keys} mode="inline" onOpenChange={e => { setSiderOpenKeys([e[1]]) }}>{dom_sider}</Menu>
        }
      </Sider>
      <Layout className="mos-right">
        <Header className={`header ${is_magnify ? 'hide' : ''}`} style={{ padding: 0 }}>
          <div className='menu'>
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'btn',
              onClick: () => { setCollapsed(!collapsed) },
            })}
            <Dropdown className='btn' menu={{ items: platforms_menu }}>
              <AppstoreOutlined />
            </Dropdown>
            <Menu mode="horizontal" selectedKeys={menu_selected_keys}>{dom_menu}</Menu>
          </div>
          <div>
            <div className="btn" onClick={() => {
              navigate('/manage/chat')
            }}>
              <Badge count={msg_unread}>
                <BellOutlined />
              </Badge>
            </div>
            {dom_tenant}
            {/* <div className="btn" title="消息" onClick={() => { navigate('/system/my/msg') }}>
              <Badge size="small" count={2}>
                <MessageOutlined />
              </Badge>
            </div> */}
            <Dropdown menu={{ items: user_drop }}>
              <div className="btn">
                {
                  member.avatar ?
                    <Avatar size="small" src={Func.getRealUrl(member.avatar)} />
                    :
                    <Avatar size="small" icon={<UserOutlined />} />
                }
                <span className="name">{member.name}</span>
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content className="content">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default Main