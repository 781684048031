import { config } from '@/common'

function Main(props: any) {
    const year_start = 2023
    const year = (new Date()).getFullYear()
    return (
        <div className="copyright">
            © {(year === year_start) || `${year_start} - `}{year} {props.text}
        </div>
    )
}
export default Main