import { Link, post, useState, MyIcon, useNavigate, useEffect, MsgCaptcha, get, Func, config } from '@/common';
import { Form, Input, Button } from 'antd';
import {
    MobileOutlined,
    KeyOutlined,
    SafetyOutlined
} from '@ant-design/icons';
import '@/assets/style/entrance.scss';
import Copyright from '@/components/Copyright';
// import { useParams } from 'react-router-dom';

function Main(props: any) {
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [type, setLoginType] = useState('password');
    const [platforms, setPlatforms] = useState(JSON.parse(localStorage.getItem('platforms') || '{}'))
    const [backgroundImage, setBackgroundImage] = useState<any>(null)

    const onFinish = (data: any) => {
        setLoading(true)
        data['type'] = type
        post('/_system/login', data).then(data => {
            if (data.code) return
            data = data.data
            localStorage.setItem('member', JSON.stringify(data.member))
            localStorage.setItem('module', JSON.stringify(data.module))
            // localStorage.setItem('config', JSON.stringify(data.config))
            window.location.href = '/'
        }).finally(() => {
            setLoading(false)
        })
    }
    // 获取平台
    const getPlatforms = () => {
        get('/_system/opens/platforms').then(data => {
            data = data.data
            setPlatforms(data)
            localStorage.setItem('platforms', JSON.stringify(data))
            document.title = data[config.app.id].name
            if (data[config.app.id].background) setBackgroundImage(Func.getRealUrl(data[config.app.id].background))
        })
        if (platforms[config.app.id] && platforms[config.app.id].background) {
            setBackgroundImage(Func.getRealUrl(platforms[config.app.id].background))
        }
    }
    useEffect(() => {
        getPlatforms()
        // 已登录跳转
        if (localStorage.getItem('member')) {
            navigate('/')
        }
    }, [])
    return (
        <div className="entrance" style={backgroundImage && { backgroundImage: `url(${backgroundImage})` }}>
            <Form
                className="form"
                onFinish={onFinish}
                form={form}
            >
                <img className="logo" src="/images/logo.jpg" alt="LOGO" />
                <div className="name">{platforms[config.app.id] && platforms[config.app.id].name}</div>
                <Form.Item
                    name="phone"
                    rules={[{ required: true, message: '手机号为必填' }]}
                >
                    <Input size="large" placeholder="请输入手机号" prefix={<MobileOutlined />} />
                </Form.Item>
                {
                    type === 'password' ?
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: '密码为必填' }]}
                        >
                            <Input.Password size="large" placeholder="请输入密码" prefix={<KeyOutlined />} />
                        </Form.Item>
                        :
                        <Form.Item
                            className="captcha"
                        >
                            <Form.Item
                                noStyle
                                name="captcha"
                                rules={[{ required: true, message: '验证码为必填' }]}
                            >
                                <Input size="large" placeholder="请输入验证码" maxLength={4} prefix={<SafetyOutlined />} />
                            </Form.Item>
                            <MsgCaptcha form={form} type="login" />
                        </Form.Item>

                }
                <Button size="large" type="primary" htmlType="submit" block loading={loading}>
                    登录
                </Button>
                <div className="register">
                    {/* <Link to="/register">立即注册</Link> */}
                    <div></div>
                    <Link to="/find_pw">忘记密码？</Link>
                </div>
                <div className="line">
                    <div></div>
                    <span>其它登录方式</span>
                    <div></div>
                </div>
                <div className="other">
                    {
                        type === 'password' ?
                            <MyIcon className="phone" type="icon-shoujihao" title="手机号登录" onClick={() => { setLoginType('phone') }} /> :
                            <MyIcon className="phone" type="icon-mima" title="密码登录" onClick={() => { setLoginType('password') }} />

                    }
                    <MyIcon className="wx" type="icon-weixin" title="微信号登录" />
                </div>
            </Form>
            <Copyright text={platforms[config.app.id] && platforms[config.app.id].name} />
        </div>
    )
}

export default Main